import { screen, cleanup, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { FormProvider, useForm } from 'react-hook-form';

import { AccommodationRequestFormFields } from '../../../types';
import CreateForm from '../CreateForm';

import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import renderComponent from '@/helpers/tests/renderComponent';

jest.mock('@/modules/AccommodationRequest/api', () => ({
  useGetAccommodationSitesListQuery: () => ({
    data: [
      {
        id: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
        name: 'FMG Camp 145',
      },
      {
        id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
        name: 'FMG Hamilton',
      },
    ],
  }),
}));

const CreateFormTest = () => {
  const formMethods = useForm<AccommodationRequestFormFields>({
    defaultValues: {
      phoneNumber: '',
      comment: '',
      guestFirstName: '',
      guestLastName: '',
      site: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
      dateFields: {
        arrivalDate: new Date('2023-09-25T19:02:55.059'),
        departureDate: new Date('2023-09-26T12:00:00.059'),
      },
    },
  });
  return (
    <FormProvider {...formMethods}>
      <CreateForm />
    </FormProvider>
  );
};

const mockSummaryProps = {};

jest.mock('react-hook-form', () => ({
  ...jest.requireActual('react-hook-form'),
  useFormContext: () => ({
    handleSubmit: () => jest.fn(),
    formState: () => jest.fn(),
    getValues: () => {
      return mockSummaryProps;
    },
  }),
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

describe('CreateForm', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let formTitle: HTMLElement,
      nextButton: HTMLElement,
      guestButton: HTMLElement,
      meButton: HTMLElement;

    renderComponent(CreateFormTest);

    beforeEach(() => {
      formTitle = screen.getByTestId('accommodation-request-create-form-title');
      nextButton = screen.getByTestId('button-action-primary');
      guestButton = screen.getByTestId('button-request-for-guest-wrapper');
      meButton = screen.getByTestId('button-request-for-me-wrapper');
    });

    it('should render accomodation create form screen', async () => {
      expect(formTitle).toBeTruthy();
      expect(nextButton).toBeTruthy();
      expect(guestButton).toBeTruthy();
      expect(meButton).toBeTruthy();
      expect(screen.getByText('Your phone number *')).toBeTruthy();
      expect(screen.getByText('Site location')).toBeTruthy();
      expect(screen.getByText('Arrival date')).toBeTruthy();
      expect(screen.getByText('Departure date')).toBeTruthy();
      expect(screen.getByText('Arrival time')).toBeTruthy();
      expect(screen.getByText('Departure time')).toBeTruthy();
      expect(screen.getByText('Comment *')).toBeTruthy();

      await userEvent.type(
        screen.getByTestId('accomodation-request-phone-hook-input-field'),
        '3224423'
      );
      expect(screen.getByDisplayValue('3224423')).toBeInTheDocument();
      userEvent
        .type(screen.getByTestId('comment'), 'I would like to have room with a sea view')
        .then(() =>
          expect(screen.getByTestId('comment')).toHaveValue(
            'I would like to have room with a sea view'
          )
        );

      expect(screen.getByText('Mon, 09/25/2023')).toBeTruthy();
      expect(screen.getByText('Tue, 09/26/2023')).toBeTruthy();
      expect(screen.getByText('19:02')).toBeTruthy();
      expect(screen.getByText('12:00')).toBeTruthy();
      expect(screen.getByText('FMG Camp 145')).toBeTruthy();

      expect(screen.queryByText('Guest first name *')).toBeNull();
      expect(screen.queryByText('Guest last name *')).toBeNull();
      expect(screen.queryByText('Email of guest *')).toBeNull();
    });
  });

  describe('with enable guest request setupOption enabled', () => {
    beforeEach(() => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
    });

    renderComponent(CreateFormTest);

    it('should display email, first name and last name input', async () => {
      await waitFor(() => {
        expect(screen.getByText('Email of guest *')).toBeTruthy();
        expect(screen.getByText('Guest first name *')).toBeTruthy();
        expect(screen.getByText('Guest last name *')).toBeTruthy();
        expect(screen.queryByText('Your phone number *')).toBeNull();
      });
    });
  });
});
