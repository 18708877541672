import { InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser';

/*
 * Some input types are disabled to match Ionic TextFieldTypes type definition
 */
enum INPUT_TYPE {
  // BUTTON = "button",
  // CHECKBOX = "checkbox",
  // COLOR = "color",
  DATE = 'date',
  DATETIMELOCAL = 'datetime-local',
  EMAIL = 'email',
  // FILE = "file",
  // HIDDEN = "hidden",
  // IMAGE = "image",
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  // RADIO = "radio",
  // RANGE = "range",
  // RESET = "reset",
  SEARCH = 'search',
  // SUBMIT = "submit",
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week',
}

enum POSITION {
  LEFT = 'left',
  RIGHT = 'right',
}

enum SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

enum RADIO_STYLE {
  TRANSPARENT = 'TRANSPARENT',
}

enum APP_NAME {
  MYWAY = 'myway',
  WANDO = 'wando',
  MYVILLAGE = 'myvillage',
}

enum APP_DISPLAY_NAME {
  MYWAY = 'MyWay',
  WANDO = 'Wando',
  MYVILLAGE = 'MyVillage',
}

enum SSO_PROVIDER {
  GATES = 'G@tes',
}

const IN_APP_BROWSER_FRAMELESS: InAppBrowserOptions = {
  zoom: 'no',
  clearcache: 'yes',
  clearsessioncache: 'yes',
  location: 'no',
};
const IN_APP_BROWSER_WITH_LOCATION: InAppBrowserOptions = {
  zoom: 'no',
  clearcache: 'yes',
  clearsessioncache: 'yes',
  location: 'yes',
};

const EMOJIS = {
  wave: 0x1f44b,
  confused: 0x1f615,
  party: 0x1f389,
  speech: 0x1f4ac,
  robot: 0x1f916,
};

const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  ACCESS_TOKEN_VALID_UNTIL: 'accessTokenValidTill',
  REFRESH_TOKEN: 'refreshToken',
  CURRENT_REGION_CODE: 'currentRegionCode',
  CURRENT_GEO_CODE: 'currentGeoCode',
  PUSH_NOTIFICATION_REGISTRATION_DATA: 'pushNotificationRegistrationData',
  USER_EMAIL: 'userEmail',
  RETURN_URL: 'returnUrl',
  SHARED_SITE_CODE: 'siteCodeAfterOnboarding',
};

const APP_ERRORS = {
  INDEX_DB_SUPPORT: 'INDEX_DB_SUPPORT',
  NOT_FOUND: 'NOT_FOUND',
  SITE_LINK_INVALID: 'SITE_LINK_INVALID',
  FORBIDDEN: 'FORBIDDEN',
};

const PERMISSION_ERRORS = {
  SITE_HIDDEN: 'SITE_HIDDEN',
  CONTRACT_DIFFERENT: 'CONTRACT_DIFFERENT',
};

enum SUPPORTED_FILE_EXTENSION_MIMETYPES {
  png = 'image/png',
  jpg = 'image/jpg',
  jpeg = 'image/jpeg',
  gif = 'image/gif',
  bmp = 'image/bmp',
  pdf = 'application/pdf',
}

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

const ONE_YEAR_TIMESTAMP = 12 * 24 * 60 * 60 * 1000;

export enum WIDGET_TYPE {
  MINI = 'mini',
  NORMAL = 'normal',
}

export type AppNames = 'myway' | 'wando' | 'myvillage';

export type DaySection =
  | 'GOOD_EVENING_AFTER_MIDNIGHT'
  | 'GOOD_MORNING'
  | 'GOOD_AFTERNOON'
  | 'GOOD_EVENING_BEFORE_MIDNIGHT';

const HERO_DEFAULT_IMAGES = {
  GOOD_EVENING_AFTER_MIDNIGHT: `${process.env.PUBLIC_URL}/assets/widgets/hero-good-evening-after_midnight.jpg`,
  GOOD_MORNING: `${process.env.PUBLIC_URL}/assets/widgets/hero-good-morning.jpg`,
  GOOD_AFTERNOON: `${process.env.PUBLIC_URL}/assets/widgets/hero-good-afternoon.jpg`,
  GOOD_EVENING_BEFORE_MIDNIGHT: `${process.env.PUBLIC_URL}/assets/widgets/hero-good-evening-before-midnight.jpg`,
};

export const APP_HERO_WIDGET_IMAGE = {
  default: HERO_DEFAULT_IMAGES,
  myway: HERO_DEFAULT_IMAGES,
  bite: HERO_DEFAULT_IMAGES,
  wando: HERO_DEFAULT_IMAGES,
  myvillage: HERO_DEFAULT_IMAGES,
};

export const NOTIFICATIONS_PAGESIZE = 10;

export const SETUP_OPTIONS = {
  FOOD_ORDER: {
    disableTimeSelection: 'disableTimeSelection',
    useGetMenuBeforeSubmit: 'useGetMenuBeforeSubmit',
    enableGuestOrdering: 'enableGuestOrdering',
  },
  RETAIL: {
    transactionalFeedbackFrom: 'transactionalFeedbackFrom',
    transactionalFeedbackTags: 'transactionalFeedbackTags',
    shareSiteIdentifier: 'ShareSiteIdentifier',
    hideTaxAndSubtotalAmount: 'hideTaxAndSubtotalAmount',
  },
  MY_ACCOUNT: {
    disableNotifications: 'disableNotifications',
  },
};

const HIDE_NAVIGATION_ROUTES = ['/access', '/account/information', '/public_legal', '/onboarding'];

export {
  INPUT_TYPE,
  POSITION,
  RADIO_STYLE,
  SIZE,
  APP_NAME,
  APP_DISPLAY_NAME,
  SSO_PROVIDER,
  SUPPORTED_FILE_EXTENSION_MIMETYPES,
  IN_APP_BROWSER_FRAMELESS,
  IN_APP_BROWSER_WITH_LOCATION,
  EMOJIS,
  LOCAL_STORAGE_KEYS,
  APP_ERRORS,
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSION_ERRORS,
  ONE_YEAR_TIMESTAMP,
  HIDE_NAVIGATION_ROUTES,
};
