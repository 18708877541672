import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { getAppSpecificData } from '../../../helpers/misc';
import { State } from '../../../types/state.types';
import { useGetSitesQuery, useLazyGetSiteByCodeQuery, useSelectSiteMutation } from '../api/api';
import {
  DEFAULT_DISPLAY_TYPE,
  pagePaths,
  canEnableUserLocation,
  canHidePublicSitesLocation,
} from '../config';
import { useSiteTranslation } from '../hooks/useSiteTranslation';
import { ISite } from '../types/sites.types';

import LocationListPage from './LocationListPage/LocationListPage';
import { CodeErrors } from './SitesList.types';

import useLoginStatus from '@/helpers/hooks/useLoginStatus';

const SitesList = () => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [sitesReady, setSitesReady] = useState<boolean>(false);
  const [sitesWithGeoLocation, setSitesWithGeoLocation] = useState<ISite[]>([]);
  const { data: allSites = [], isFetching: loading, isError } = useGetSitesQuery({});
  const [selectSite, { isLoading: isSelecting }] = useSelectSiteMutation();
  const [getSiteByCode, { isLoading: getSiteByCodeLoading }] = useLazyGetSiteByCodeQuery();

  const isGetSiteLocked = useSelector(
    (state: State) => state.Core.locks.registrationContractAndSite || false
  );
  const { isSSOUser } = useLoginStatus();
  const { hidePublicSitesLocation } = canHidePublicSitesLocation();
  const dispatch = useDispatch();
  const { visited } = useSelector((state: State) => state.Sites);
  const sitesOnOnboarding = useSelector(
    (state: State) => state.Core.access.shouldSelectSiteContext
  );
  const { label } = useSiteTranslation(__filename);

  useEffect(() => {
    if (allSites.length) {
      let availableSites: ISite[] = allSites;

      if (isSSOUser && hidePublicSitesLocation) {
        availableSites = availableSites.filter(
          (site) => (site.siteAccessRestrictionLevelId || 0) !== 1
        );
      }

      setSitesWithGeoLocation(availableSites.filter((site) => site.geoCoordinates?.latitude));

      setSites(availableSites);
    }
    setSitesReady(true);
  }, [allSites, isSSOUser, hidePublicSitesLocation]);

  const { push } = useHistory();
  const { state } = useLocation<{ onSwitchSitePath: undefined }>();

  const [codeError, setCodeError] = useState<CodeErrors | undefined>();
  const { enableUserLocation } = canEnableUserLocation();

  const afterSiteChanged = useCallback(() => {
    const redirectUrl = state?.onSwitchSitePath ?? '/';
    push(redirectUrl);
  }, [state, push]);

  const handleValidateCode = useCallback(
    async (siteCode: string, closeModal: () => void) => {
      const { data: { site } = {}, error } = await getSiteByCode({ code: siteCode });

      if (site) {
        closeModal();
        await selectSite({
          dispatch: dispatch,
          site: {
            id: site.id,
            name: site.name,
            locationtype: 'Site',
          },
          shouldInvalidateCache: true,
        });
        afterSiteChanged();

        return;
      }

      const { status } = (error || {}) as { status: number };
      switch (status) {
        case 401:
          setCodeError(CodeErrors.UNAUTHORIZED);
          break;
        case 403:
          setCodeError(CodeErrors.FORBIDDEN);
          break;
        case 404:
          setCodeError(CodeErrors.NOT_FOUND);
          break;
        default:
          setCodeError(CodeErrors.GENERIC_ERROR);
      }
    },
    [getSiteByCode, dispatch, selectSite, afterSiteChanged]
  );

  const handleScanCode = useCallback(
    async ({ response }: QRCodeReaderResponse, openErrorModal: () => void) => {
      if (response) {
        const { data: { site } = {}, error } = await getSiteByCode({ code: response });

        if (site) {
          await selectSite({
            dispatch,
            site: {
              id: site.id,
              name: site.name,
              locationtype: 'Site',
            },
            shouldInvalidateCache: true,
          });
          afterSiteChanged();

          return;
        }

        const { status } = (error || {}) as { status: number };
        switch (status) {
          case 401:
            setCodeError(CodeErrors.UNAUTHORIZED);
            openErrorModal();
            break;
          case 403:
            setCodeError(CodeErrors.FORBIDDEN);
            openErrorModal();
            break;
          case 404:
            setCodeError(CodeErrors.NOT_FOUND);
            openErrorModal();
            break;
          default:
            setCodeError(CodeErrors.GENERIC_ERROR);
        }
      }
    },
    [getSiteByCode, selectSite, afterSiteChanged, dispatch]
  );

  const redirectToSite = useCallback(
    async (site: ISite | null) => {
      if (!!site) {
        await selectSite({ site, shouldInvalidateCache: false, dispatch });
        afterSiteChanged();
      }
    },
    [selectSite, afterSiteChanged, dispatch]
  );

  const onSiteSelection = useCallback(
    async (site: ISite) => {
      redirectToSite(site);
    },
    [redirectToSite]
  );

  if ((!isError && !sitesReady && !sites.length) || isSelecting || loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <LocationListPage
        withNavBar={sitesOnOnboarding && sitesOnOnboarding === true ? false : true}
        hasBackLink={false}
        title={label('Ref: Page title')}
        sites={sites}
        visited={visited}
        siteCodeError={codeError}
        label={label}
        pagePaths={pagePaths}
        isSiteCodeLocked={isGetSiteLocked || getSiteByCodeLoading}
        onSiteSelection={onSiteSelection}
        onValidateSiteCode={handleValidateCode}
        onScanSiteCode={handleScanCode}
        handleCodeError={setCodeError}
        defaultDisplayType={getAppSpecificData(DEFAULT_DISPLAY_TYPE)}
        isMapEnabled={!!sitesWithGeoLocation.length}
        enableUserLocation={enableUserLocation}
      />
    </>
  );
};

export default SitesList;
