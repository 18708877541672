import Button from '../../../../components/atoms/Button';
import { TileAction } from '../../../../components/molecules/Tile';
import { SIZE } from '../../../../constants';
import { TranslationProps } from '../../../../localization/localization.types';

import { LocationTileProps } from './LocationItem.types';

import {
  FileTreeIcon,
  ChevronRightIcon,
  BuildingIcon,
  FloorIcon,
  AisleIcon,
  RoomIcon,
} from '@/assets/icons';
import { LocationProps } from '@/modules/ServiceRequest/types/types';

export const renderLocationItem = (
  loc: LocationProps,
  allowLocationSelectionFromLevel: number,
  primaryAction: (loc: LocationProps) => void,
  secondaryAction: (loc: LocationProps) => void,
  label: TranslationProps['label'],
  isPrefferedLocation: boolean = false,
  disableSecondaryAction: boolean = false
) => {
  let actions: TileAction[] = [];
  // tileAction is the primary action of the tile
  let tileAction:
    | React.MouseEventHandler<HTMLIonButtonElement | HTMLIonCardElement | HTMLDivElement>
    | undefined = undefined;
  // A location is selectable when its level is authorized by allowLocationSelectionFromLevel
  if (
    isPrefferedLocation ||
    (loc.level && (loc.level >= allowLocationSelectionFromLevel || !loc.hasChildren))
  ) {
    tileAction = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      primaryAction(loc);
    };

    actions.push({
      name: label('Ref: Select'),
      'data-testid': `location-item-primary-action-${loc.id}`,
      customAction: (
        <Button
          look="primary"
          size={SIZE.SMALL}
          onClick={tileAction}
          suffix={ChevronRightIcon}
          data-testid={`location-item-primary-action-${loc.id}`}
        >
          {label('Ref: Select')}
          <span className="sr-only">{loc.name}</span>
        </Button>
      ),
    });
  }

  if (loc.hasChildren) {
    const handleNavigate = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      secondaryAction(loc);
    };

    // if drillDownLocation is primary action
    if (!tileAction) {
      tileAction = handleNavigate;
    }

    actions.push({
      name: label('Ref: Navigate'),
      'data-testid': `location-item-secondary-action-${loc.id}`,
      customAction: (
        <Button
          look="secondary"
          size={SIZE.SMALL}
          disabled={disableSecondaryAction}
          onClick={handleNavigate}
          suffix={FileTreeIcon}
          data-testid={`location-item-secondary-action-${loc.id}`}
        >
          {label('Ref: Navigate')}
        </Button>
      ),
    });
  }

  return {
    ...{
      id: loc.id,
      title: loc.name,
      belowTitle: loc.title ? loc.title.substring(loc.title.indexOf(',') + 1) : '',
      actions,
      highlightedActionsInheritStyle: 'highlightedActions',
      level: loc.level,
    },
    ...(tileAction ? { onClick: tileAction } : {}),
  };
};

export const renderLocationSections = (
  label: TranslationProps['label'],
  myLocation?: LocationTileProps,
  preferredLocation?: LocationTileProps,
  searchString?: string,
  parentLocations?: LocationProps[]
) => {
  let locationLevels = [{ id: 1, key: 1, title: label('Ref: Building'), icon: <BuildingIcon /> }];
  //TODO: Hotfix - At the first level, we display only Building
  if (parentLocations?.length !== 0 || searchString) {
    locationLevels = [
      ...locationLevels,
      { id: 2, key: 2, title: label('Ref: Floor'), icon: <FloorIcon /> },
      { id: 3, key: 3, title: label('Ref: Aisle'), icon: <AisleIcon /> },
      { id: 4, key: 4, title: label('Ref: Room'), icon: <RoomIcon /> },
    ];
  }

  const sections = [];
  if (myLocation) {
    sections.push({
      id: 5,
      key: 5,
      title: label('Ref: Selected Location'),
      filter: () => {
        const arr = [];
        arr.push(myLocation);
        return arr;
      },
    });
  }
  if (preferredLocation && parentLocations?.length === 0) {
    sections.push({
      id: 6,
      key: 6,
      title: label('Ref: Preferred Location'),
      filter: () => {
        const arr = [];
        arr.push(preferredLocation);
        return arr;
      },
    });
  }

  for (const level of locationLevels) {
    sections.push({
      id: level.id,
      key: level.key,
      title: level.title,
      icon: level.icon,
      filter: (items: LocationTileProps[]) =>
        items.filter(
          (item: LocationTileProps) =>
            item.level === level.id &&
            item.id !== myLocation?.id &&
            item.id !== preferredLocation?.id
        ),
    });
  }
  return sections;
};
